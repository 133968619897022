const state = {
    user: null,
    authInstance: null,
    firstTime: true
}

const getters = {
    user: (state) => state.user,
    isGuest: (state) => {
        if (state.user) {
            return state.user.getBasicProfile().getEmail() == 'guest@appchoose.io'
        }
    },
    authInstance: (state) => state.authInstance,
    isAuthenticated: (state) => {
        return state.user !== null
    },
    firstTime: (state) => state.firstTime
}

const mutations = {
    setUser: (state, user) => { 
        state.user = user 
    },
    setAuthInstance: (state, authInstance) => {
        state.authInstance = authInstance
    },
    setFirstTime: (state, bool) => {
        state.firstTime = bool
    }
}

const actions = {
    initAuthInstance({ commit }) {
        const authInstance = window.gapi.auth2.getAuthInstance()
        commit('setAuthInstance', authInstance)
    },
    updateUser({ commit, dispatch }) {
        if (!state.authInstance) {
            dispatch('initAuthInstance')
        }
        commit('setUser', state.authInstance.currentUser.get())
    },
    logout({ commit, dispatch }) {
        if (!state.authInstance) {
            dispatch('initAuthInstance')
        }
        state.authInstance.signOut()
        commit('setUser', null)
    },
    updateFirstTime({ commit }) {
        commit('setFirstTime', false)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}