import quantile from 'compute-quantile'

export const computeQuartiles = (table) => {
    /* Dictionary containing the quartiles for each numerical quantity
    available in the table. */
    const columns = ['orders', 'ca', 'avg_price', 'avg_order', 'avg_shipping', 
    'click_sale', 'click_item', 'add_to_cart', 'conversion', 'followers', 'pulse_ca', 
    'returns', 'avg_discount', 'first_day_ca']
    let q = {}
    for (let i = 0; i < columns.length; i++) {
        let c = columns[i]
        const arr = Array.isArray(table) ? table.filter(row => row.status === 'closed').map((row) => {
            return parseFloat(row[c])
        }) : []
        q[c] = { 'q1': quantile(arr, 0.25), 'q3': quantile(arr, 0.75) }
    }
    return q
}
