<template>
    <div> 
        <highcharts :options="chartOptions"/>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
more(Highcharts);

let { jStat } = require('jstat')

export default {
    components: {
        highcharts: Chart
    },
    props: {
        name: String,
        values: Array
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'boxplot',
                    height: 100,
                    width: 400,
                    inverted: true
                },
                title: {
                    text: ''
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    title: {
                        text: ''
                    }

                },
                xAxis: {
                    categories: [],
                    title: {
                        text: ''
                    },
                    labels: {
                        enabled: false
                    }

                },
                series: [{
                    name: '',
                    data: null
                }]
            }
        }
    },
    watch: {
        values() {
            this.populateChartValues()
        }
    },
    methods: {
        populateChartValues() {
            const quartiles = jStat.quartiles(this.values)
            this.chartOptions.xAxis.categories = [this.name]
            this.chartOptions.series[0].name = this.name
            this.chartOptions.series[0].data = [{
                "color": "#2196f3",
                "name": this.name,
                "low": Math.min(...this.values),
                "q1": quartiles[0],
                "median": quartiles[1],
                "q3": quartiles[2],
                "high": Math.max(...this.values)
            }]
        }
    },
    mounted() {
        this.populateChartValues()
    }
}
</script>

<style>

</style>