<template>
    <div class="text-loader">
        <p class="text-loader-message">
            Chargement des données
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </p>
        <Tips v-if="tips"/>
    </div>
</template>

<script>
import Tips from '@/components/Tips'

export default {
    props: {
        message: String,
        tips: Boolean
    },
    components: {
        Tips
    }
}
</script>

<style scoped>
.text-loader {
    margin: auto;
    margin-top: 100px;
    text-align: center;
    justify-content: center;
}

.text-loader-message {
    font-size: 18x;
    font-weight: 700;
    margin-bottom: 75px;
}

@keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

.text-loader-message span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.text-loader-message span:nth-child(2) {
    animation-delay: .2s;
}

.text-loader-message span:nth-child(3) {
    animation-delay: .4s;
}
</style>