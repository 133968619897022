const state = {
    badges: [ "Made in France", "B-Corporation", "Made in Europe", "Cadeau parfait", "Artisanal", "Durable", "Bio", 
        "Concept unique", "Recyclable", "Fait main", "Premium", "Upcycling", "Série Limitée",
        "Socialement Engagée", "Vegan", "Savoir-Faire", "Éco-Friendly", "Oeko-Tex", "Innovation"
    ],
    selectedBadges: [],
    selectedFields: []
}

const getters = {
    badges: (state) => state.badges,
    selectedBadges: (state) => state.selectedBadges,
    selectedFields: (state) => state.selectedFields
}

const mutations = {
    setSelectedBadges: (state, badges) => { 
        state.selectedBadges = badges
    },
    setSelectedFields: (state, fields) => { 
        state.selectedFields = fields
    } 
}

const actions = {
    updateSelectedBadges({ commit }, badges) {
        commit('setSelectedBadges', badges)
    },
    updateSelectedFields({ commit }, fields) {
        commit('setSelectedFields', fields)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}