<template>
    <div class="scorebar">
        <p v-if="name == 'Prix moyen'">
            <span><strong>{{ name }}</strong> : {{ mean.toFixed(0) }}€</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ quartiles[1].toFixed(0) }}€</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>
        </p>
        <p v-if="name == 'Réduction'">
            <span><strong>{{ name }}</strong> : {{ mean.toFixed(0) }}%</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ quartiles[1].toFixed(0) }}%</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>
        </p>

        <p v-if="name == 'Abonnés'">
            <span><strong>{{ name }}</strong> : {{ fmtFollowers(mean) }}</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ fmtFollowers(quartiles[1]) }}</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>
        </p>
        
        <p v-if="name == 'Livraison'">
            <span><strong>{{ name }}</strong> : {{ mean.toFixed(2) }}€</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ quartiles[1].toFixed(2) }}€</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>
        </p>
        <p v-if="name == 'Articles disponibles'">
            <span><strong>{{ name }}</strong> : {{ mean.toFixed(0) }}</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ quartiles[1].toFixed(0) }}</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>        
        </p>
        <p v-if="name == 'Retours'">
            <span><strong>{{ name }}</strong> : {{ mean.toFixed(0) }}</span>
            <br>
            <span style="font-size: 12px;">contre <strong>{{ quartiles[1].toFixed(0) }}</strong> observés en moyenne dans la catégorie <strong>{{ category }}</strong></span>        
        </p>
        <k-progress :percent="value" :show-text="false" :color="color"></k-progress>
        <hr>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        category: String,
        mean: Number,
        quartiles: Array,
        score: Number
    },
    data() {
        return {
            
        }
    },
    computed: {
        value() {
            return Math.max(Math.min(100, this.score), 5)
        }, 
        color() {
            if (this.value > 50) {
                return '#27ae60'
            } else if (this.value > 25 && this.value <= 50) {
                return 'orange'
            } else {
                return 'red'
            }
        }
    },
    methods: {
        fmtFollowers(value) {
            return (value > 1000) ? `${parseInt(Math.round(value / 1000))}K` : '<1K'
        }
    }
}
</script>

<style>

</style>