const state = {
    categories: []
}

const getters = {
    categories: (state) => state.categories
}

const mutations = {
    setCategories: (state, data) => { 
        state.categories = data 
    }
}

const actions = {
    async loadCategories({ commit }) {
        //const url = process.env.VUE_APP_GRAPHQL_ENDPOINT
        const url = '/categories'
        const auth = window.gapi.auth2.getAuthInstance()
        const headers = {}
        if (auth.isSignedIn.get()) {
            headers.Authorization = `Bearer ${auth.currentUser.get().getAuthResponse().id_token}`
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        })
        const data = await response.json()
        commit('setCategories', data.categories)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}