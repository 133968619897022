const namespaced = true

const state = {
    loading: false
}

const getters = {
    isLoading: (state) => state.loading
}

const mutations = {
    setLoadingState: (state, bool) => { 
        state.loading = bool 
    }
}

const actions = {
    startLoader({ commit }) {
        commit('setLoadingState', true)
    },
    stopLoader({ commit }) {
        commit('setLoadingState', false)
    }
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}