<template>
    <div class="tip">
        <h3>
            <img style="vertical-align: text-top;" src="@/assets/lightbulb.jpg" width="30px;"/>
            Le saviez-vous ?
        </h3>
        <div v-if="tipIndex < tips.length">
            <p>{{ this.tip ? this.tip.text : '' }}</p>
            <img :src="this.tip ? this.tip.img : ''"/>
        </div>

        <div v-if="tipIndex == tips.length">
            <p style="background: #f2f4f5; padding: 15px;">
                <span style="font-family: Coiny;">"</span>
                <span style="font-family: Poppins; font-style: italic">{{ fact }}</span>
                <span style="font-family: Coiny;">"</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tip: null,
            tipIndex: null,
            tipImg: null,
            tips: [
                { 
                    text: "Vous pouvez afficher les différentes OP d'une marque directement depuis son historique de passage.",
                    img: require('@/assets/tip_history.png')
                },
                { 
                    text: "Vous pouvez cliquer sur le nom d'une marque pour ouvrir la vente correspondante dans meusnidus",
                    img: require('@/assets/tip_meusnidus.png')
                },
                { 
                    text: "Le nombre d'étoiles correspond au CA généré par une vente à sa sortie, rapporté au nombre d'utilisateurs présents au premier jour de la vente.",
                    img: require('@/assets/tip_starrating.png')
                },
                { 
                    text: "Les statistiques par catégorie sont disponibles !",
                    img: require('@/assets/tip_statistics.png')
                },
                { 
                    text: "Pour quitter la page d'une vente, vous pouvez simplement appuyer sur Esc.",
                    img: require('@/assets/tip_escape.jpg')
                },
                { 
                    text: "Vous pouvez exporter le résultat d'une recherche au format .csv pour les utiliser dans le tableur de votre choix.",
                    img: require('@/assets/tip_download.png')
                },
                { 
                    text: `
                        L'emoji 🔥 donne une indication rapide sur le succès d'un produit !
                        Pour cela il faut néanmoins que la vente correspondante ait vendu plus de 50 articles.
                        Cela permet de voir les marques où un produit se distingue des autres et celles qui sont
                        plus hétérogènes.`,
                    img: require('@/assets/tip_fire.png')
                },
                { 
                    text: `Vous pouvez également filtrer les ventes par badges. Si vous sélectionnez plusieurs badges, les ventes affichées sont celles
                    qui possèdent au moins tous ces badges.`,
                    img: require('@/assets/tip_badges.png')
                }
            ],
            fact: ''
        }
    },
    methods: {
        async getFunFact(tip) {
            if (tip == this.tips.length) {
                
                const response = await fetch('https://uselessfacts.jsph.pl/random.json?language=en')
            
                let fact

                if (response.status == 200) {
                    const data = await response.json()
                    fact = data.text
                } else {
                    fact = "'meus nidus' signifie 'mon nid' en latin."
                }
                
                this.fact = fact
            }
        }
    },
    mounted() {
        this.tipIndex = Math.floor(Math.random() * this.tips.length)
        this.tip = this.tips[this.tipIndex]
        this.getFunFact(this.tipIndex)
    }
}
</script>

<style>
.tip {
    background: white;
    border-radius: 25px;
    width: 500px;
    max-width: 90%;
    height: 500px;
    padding: 50px;
    margin: auto;
}

.tip > h3 {
    margin-bottom: 50px;
}

.tip > div > img {
    width: 100%;
}
</style>