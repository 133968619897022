import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartBar, faUser, faHome, faHandHoldingUsd, faArrowUp, faArrowDown, faCalendarAlt, faDownload, faSync, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import JsonCSV from 'vue-json-csv'
import KProgress from 'k-progress'

library.add(faChartBar, faUser, faHome, faHandHoldingUsd, faArrowUp, faArrowDown, faCalendarAlt, faDownload, faSync, faLightbulb)

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('downloadCsv', JsonCSV)
Vue.component('k-progress', KProgress)

new Vue({
  render: h => h(App),
  router: router,
  store
}).$mount('#app')

