import { GraphQLClient, gql } from 'graphql-request'

const state = {
    sales: [],
    currentSale: null,
    filter: ''
}

const getters = {
    sales: (state) => state.sales,
    currentSale: (state) => state.currentSale
}

const mutations = {
    setSales: (state, data) => {
        state.sales = data
    },
    setCurrentSale: (state, sale) => {
        state.currentSale = sale
    },
    setFilter: (state, filter) => {
        state.filter = filter
    }
}

const actions = {
    async loadSales({ commit, dispatch }) {
        dispatch('loading/startLoader', null, { root: true })
        const query = gql`{
            sales {
                sale_id,
                name,
                start,
                ca,
                orders,
                avg_price,
                avg_order,
                avg_discount,
                avg_shipping,
                category,
                categories,
                items,
                cover,
                status,
                followers,
                pulse_ca,
                pulse_orders,
                click_sale,
                click_item,
                add_to_cart,
                conversion,
                appearance,
                badges,
                returns,
                sold_items,
                first_day_ca
            }
        }`
        //const url = process.env.VUE_APP_GRAPHQL_ENDPOINT + '/sales'
        const url = '/sales'
        const auth = window.gapi.auth2.getAuthInstance()
        const headers = {}
        if (auth.isSignedIn.get()) {
            headers.Authorization = `Bearer ${auth.currentUser.get().getAuthResponse().id_token}`
        }
        const client = new GraphQLClient(url, { headers: headers })
        const data = await client.request(query)
        commit('setSales', data.sales)
        dispatch('elastic/populateIndex', data.sales, { root: true })
        setTimeout(() => {
            dispatch('loading/stopLoader', null, { root: true })
        }, 2000)
    },
    updateCurrentSale({ commit }, sale) {
        commit('setCurrentSale', sale)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}