
<template>
    <div>
        <div class="login-content">
            <img src="@/assets/logo.png" alt="Kiwi standing on oval">
            <div class="btn-container">
              <div id="google-signin-btn" :style="{ visibility: btnVisibility }"></div>
              <button v-if="isAuthenticated" id="logout-btn" @click="logout">Se déconnecter</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions(['updateUser', 'logout', 'updateFirstTime', 'loadSales', 'loadCategories']),
        async onSignIn(authUser) {
            const user = await this.getUser(authUser.getAuthResponse().id_token)
            if (user) {
                this.updateUser(user)
                this.loadCategories()
                this.loadSales()
                if (this.firstTime) {
                    this.updateFirstTime()
                    this.$router.push('/')
                }
            } else {
                this.logout()
                alert("Accès refusé. Vérifiez que vous vous êtes connecté-e avec votre adresse appchoose.io.")
            }
        },
        async getUser(token) {
            const url = `https://micros-auth-jjqptnly6q-ew.a.run.app/auth?token=${token}`
            const response = await fetch(url)
            if (response.status >= 400) {
                return undefined
            } else {
                return await response.json()
            }
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'firstTime']),
        btnVisibility() {
            return this.isAuthenticated ? 'hidden' : 'visible'
        }
    },
    mounted() {
        window.gapi.signin2.render('google-signin-btn', {
            onsuccess: this.onSignIn
        })
    }
}
</script>

<style scoped>
div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-content { 
    margin: auto;
    margin-top: 150px;
    width: 300px;
    height: 450px;
    background: white;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
}

span {
    font-size: 30px;
    font-weight: 700;
}

img {
    width: 120px;
    margin: 100px 0 16px;
}

.btn-container {
    margin-top: 75px;
}

#logout-btn {
    padding: 8px 16px;
    margin: 4px 16px;
    border-radius: 25px;
    border: none;
    color: white;
    font-weight: 700;
    background: #212529;
}

#google-signin-btn {
    padding: 8px 16px; 
    margin: 4px 16px;
}
</style>