<template>
  <div class="home">
    <TextLoader v-if="isLoading" message="Chargement des données" :tips="true"/>
    
    <div v-else>
      <transition appear name="fade">
        <Datatable/>
      </transition>
    </div>
  </div>
</template>

<script>
import Datatable from '@/components/Datatable'
import TextLoader from '@/components/TextLoader'
import { mapGetters } from 'vuex'

export default {
  components: {
    Datatable,
    TextLoader
  },
  computed: {
    ...mapGetters('loading', ['isLoading'])
  }
}
</script>

<style scoped>
.home {
  width: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>