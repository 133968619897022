<template>
    <div style="width: 100%; padding: 25px;">
        <div style="padding: 5px;">
            <div class="category-filter">

                <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

                    <Multiselect 
                        v-model="selectedCategory" 
                        :options="categories"
                        :searchable="true"
                        placeholder="Sélectionner une catégorie">
                    </Multiselect>

                </div>
            </div>
        </div>

        <div v-if="selectedCategory">
            <div>
                <hr>
                <div style="display: flex">
                    <div class="mean-insights">
                        <strong>Prix moyen</strong>
                        <hr>
                        <div>
                            Valeur médiane : <strong>{{ means['avg_price'].toFixed(2) }}€</strong>
                        </div> 
                        <Boxplot name="Prix moyen" :values="Xt[0]"></Boxplot>
                    </div>
                    <div class="mean-insights">
                        <strong>Réduction</strong>
                        <hr>
                        <div>
                            Valeur médiane : <strong>{{ means['avg_discount'].toFixed(0) }}%</strong>
                        </div> 
                        <Boxplot name="Réduction" :values="Xt[1]"></Boxplot>
                    </div>
                </div>
                <div style="display: flex;">
                    <div class="mean-insights">
                        <strong>Abonnés Instagram</strong>
                        <hr>        
                        <div>
                            Valeur médiane : <strong>{{ fmtFollowers(means['followers']) }}</strong>
                        </div> 
                        <Boxplot name="Abonnés" :values="Xt[2]"></Boxplot>
                    </div>
                    <div class="mean-insights">
                        <strong>Livraison</strong>
                        <hr>   
                        <div>
                            Valeur médiane : <strong>{{ means['avg_shipping'].toFixed(2) }}€</strong>
                        </div> 
                        <Boxplot name="Livraison" :values="Xt[3]"></Boxplot>

                    </div>
                </div>
                <div style="display: flex;">
                    <div class="mean-insights">
                        <strong>Articles disponibles</strong>
                        <hr>   
                        <div>
                            Valeur médiane : <strong>{{ means['items'].toFixed(0) }}</strong>
                        </div> 
                        <Boxplot name="Articles disponibles" :values="Xt[4]"></Boxplot>
                    </div>
                    <div class="mean-insights">
                        <strong>Articles vendus</strong>
                        <hr>   
                        <div>
                            Valeur médiane : <strong>{{ means['sold_items'].toFixed(0) }}</strong>
                        </div> 
                        <Boxplot name="Articles vendus" :values="Xt[5]"></Boxplot>
                    </div>
                </div>
                
            </div>
        </div>
        <div style="padding: 5px">
            <div v-if="selectedCategory" class="correlation-insights">
                <div v-if="sampleSize >= 10">
                    <p>
                        <span><strong>Analyse de corrélation portant sur {{ sampleSize }} ventes</strong></span>
                    </p>
                    <hr>
                    <div class="row" v-for="(feature, index) in features.slice(0, 5)" :key="index">
                        <div class="col-md-4">
                            Importance {{ feature.label }} 
                        </div> 
                        <div class="col-md-2" style="text-align: right;">
                            <strong>{{ (100 * correlations[feature.key]).toFixed(1) }}%</strong>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>
                        <span><strong>Analyse de corrélation non disponible</strong></span>
                    </p>
                    <hr>
                    <p>
                        <span>Échantillon trop faible : <strong>{{ sampleSize }}</strong> {{ sampleSize > 1 ? 'ventes' : 'vente' }}</span>
                        <br>
                        <span>Minimum requis : 10 ventes</span> 
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Boxplot from '@/components/Boxplot'
let { jStat } = require('jstat')

export default {
    components: {
        Multiselect,
        Boxplot
    },
    data() {
        return {
            selectedCategory: '',
            features: [
                { key: 'avg_price', label: 'du prix' },
                { key: 'avg_discount', label: 'de la réduction' }, 
                { key: 'followers', label: "du nombre d'abonnés" },
                { key: 'avg_shipping', label: "de l'offre de livraison" },
                { key: 'items', label: "du nombre d'articles disponibles" },
                { key: 'sold_items', label: "du nombre d'articles vendus" }
            ]
        }
    },
    computed: {
        ...mapGetters(['sales', 'categories']),
        filteredSales() {
            
            let result
            
            if (this.selectedCategory.length > 0) {
                result = this.sales.filter(row => row.categories.includes(this.selectedCategory) && row.pulse_orders > 0)
            } else {
                result = []
            }

            return result

        },
        X() {
            return this.filteredSales.map(row => [row['avg_price'], row['avg_discount'], row['followers'], row['avg_shipping'], row['items'], row['sold_items']])
        },
        Xt() {
            return jStat.transpose(this.X)
        },
        y() {
            return this.filteredSales.map(row => row['pulse_orders'])
        },
        sampleSize() {
            return this.filteredSales.length
        },
        means() {

            let values = {}
                        
            if (this.filteredSales) {
                for (let i=0; i<this.features.length; i++) {
                    values[this.features[i].key] = Math.abs(
                        jStat.median(
                            this.filteredSales.map(row => row[this.features[i].key])
                        )
                    )
                } 
            }

            return values
        },
        correlations() {
            
            let coeffs = {}
            
            if (this.filteredSales) {
                for (let i=0; i<this.features.length; i++) {
                    coeffs[this.features[i].key] = Math.abs(
                        jStat.spearmancoeff(
                            this.filteredSales.map(row => row[this.features[i].key]),
                            this.y
                        )
                    )
                } 
            }

            return coeffs
        }
    },
    methods: {
        fmtFollowers(value) {
            return (value > 1000) ? `${parseInt(Math.round(value / 1000))}K` : '<1K'
        }
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                if (this.$route.name != 'home') {
                    this.$router.push({ name: 'home' })
                }
            }
        })
    }
}
</script>

<style scoped>
.category-filter {
    min-width: 700px;
    padding: 25px;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
}

.correlation-insights, .mean-insights {
    padding: 25px;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;    
    margin-bottom: 10px;
}

.mean-insights {
    min-width: 450px;
    margin-right: 5px;
    margin-left: 5px;
    width: 50%;
}

.category-filter {
    margin-bottom: 25px;
    min-height: 120px;
}

.multiselect {
    width: 50%;
    min-width: 400px;
    border-radius: 25px;
}

.multiselect__tags {
    border-radius: 15px;
}

.multiselect__option--highlight, .multiselect__option--highlight::after {
    background: #0794dc;
}
</style>