<template>
    <div class="history">
        <span><strong>Historique de {{ this.history[0].name }}</strong></span>
        <hr>
        <div class="row history-row" v-for="(element, idx) in history" 
        :key="idx" :class="{ active: element.sale_id == saleId }" @click="displaySale(element)">
            
            <div class="col-sm-4">
                <span style="white-space: nowrap;">{{ element.start }}</span>           
            </div>
            
            <div class="col-sm-1">
                <span v-if="evolution[idx] > 0">
                    <font-awesome-icon icon="arrow-up" style="color: green;"/>
                </span>
                <span v-else-if="evolution[idx] < 0">
                    <font-awesome-icon icon="arrow-down" style="color: red;"/>
                </span>
            </div>

            <div class="col-sm-4">
                <span><strong>{{ element.ca.toFixed(2) }}€</strong></span>         
            </div>

            <div class="col-sm-2">
                <span><strong>{{ element.orders }}</strong></span>         
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        saleId: String,
        history: Array
    },
    methods: {
        ...mapActions(['updateCurrentSale']),
        displaySale(row) {
            if (row.sale_id != this.saleId) {
                this.updateCurrentSale(row)
                const history = this.sales.filter(sale => sale.name === row.name).sort((a, b) => { return new Date(b.start) - new Date(a.start) })
                this.$router.push({ name: 'sale', params: { id: row.sale_id, history: history } })
            }
        }
    },
    computed: {
        ...mapGetters(['sales']),
        appearance() {
            const n = this.history.length - this.history.findIndex(row => row.sale_id == this.saleId)
            
            if (n == 1) {
                return "premier"
            } else if (n == 2) {
                return "second"
            } else {
                return parseInt(n) + "ème" 
            }
        },
        evolution() {
            let values = []            
            if (this.history.length > 1) {
                for (let i=1; i < this.history.length; i++) {
                    values.push(this.history[i-1].ca - this.history[i].ca)
                }
            }
            values.push(0)
            return values
        }
    }
}
</script>

<style scoped>
.history {
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    max-width: 500px;
    min-width: 350px;
    padding: 15px;
    margin-bottom: 25px;
}

.col-sm-4 {
    text-align: right;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

.history-row:hover {
    background: lightgray;
    cursor: pointer;
}

.active {
    background: rgba(126, 214, 223, 0.2);
}
</style>