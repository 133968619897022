<template>
  <div id="app">
    <Banner/>
    <div style="display: flex;">
      <Sidebar/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'App',
  components: {
    Banner,
    Sidebar
  }
}
</script>

<style>
@import url('//fonts.googleapis.com/css?family=Roboto:400,100,300,100italic,300italic,400italic,500italic,500,700,700italic,900,900italic');
@import url('//fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('//fonts.googleapis.com/css2?family=Coiny&display=swap');
@import url('//fonts.googleapis.com/css2?family=Rubik:wght@400;500&family=Trispace:wght@500&display=swap');

#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f4f5;
  min-width: fit-content;
}

html {
  height: 100%;
}


body {
  margin: 0px;
}
</style>