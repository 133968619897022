import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Sale from '@/views/Sale'
import Login from '@/views/Login'
import Insight from '@/views/Insight'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/sale/:id',
      name: 'sale',
      component: Sale,
      props: true
    },
    {
      path: '/insight',
      name: 'insight',
      component: Insight,
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ]
})

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  console.log(store.getters.isAuthenticated)
  console.log(to)
  if (requiresAuth) {
    if (!store.getters.isAuthenticated) {
      next({ path: '/login', params: { nextUrl: to.fullPath } })
    } else {
      const domain = store.getters.user.getBasicProfile().getEmail().split('@')[1]
      if (domain == 'appchoose.io') {
        next()
      } else {
        next(false)
      }

    }
  } else {
    next()
  }
})

export default router