<template>
  <div class="datatable">

    <div class="sales-filter">

        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
            <Multiselect 
                v-model="filter" 
                :options="categories"
                :searchable="true"
                placeholder=""
                @search-change="updateFilter">

                <template>
                    <span slot="placeholder">Rechercher une marque, trier par catégorie...</span>
                </template>

                <template>
                    <span slot="noResult"><b>{{ this.filteredSales.length }}</b> ventes correspondent à cette recherche.</span>
                </template>

            </Multiselect>

            <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows"/>
        </div>

        <div style="margin-bottom: 10px; font-size:15px; witdth: 50%; min-width: 600px;">
            <button class="badge-btn" v-b-toggle.collapse-badge-filter>Filtrer par badges</button>
            <b-collapse id="collapse-badge-filter" class="mt-2">
                <b-form-checkbox-group
                v-model="filterBadges"
                :options="badges">
                </b-form-checkbox-group>
            </b-collapse>
        </div>

        <div style="margin-bottom: 10px; font-size:15px;">
            <span style="margin-right: 10px; font-weight: 600;">Sélectionner une période :</span>
            <date-range-picker 
                ref="picker" 
                :locale-data="{ format: 'dd-mm-yyyy' }"
                minDate="2018-11-08"
                auto-apply	
                v-model="dateRange"/>
        </div>

        <div style="display: flex;">
            <span style="margin-top: 1px; margin-right: 10px; white-space: pre; font-size:15px; font-weight: 600;">Afficher :</span>
            <b-form-checkbox-group size="sm" v-model="filterFields" :options="optionalFields"/>
            <div v-if="!isGuest" style="text-align: center; margin-left: auto;">
                <download-csv :data="filteredSales" :fields="csvFields" name="sales_analytics.csv">
                    <span class="download-link">
                    <font-awesome-icon icon="download"/>
                    Télécharger le .csv
                    </span>
                </download-csv>
            </div>
        </div>
    </div>

    <div v-if="sales.length > 0" class="sales-table">
        <b-table  
            class="table borderless"
            :items="filteredSales" 
            :fields="fields" 
            :currentPage="currentPage" 
            :per-page="perPage" 
            :total-rows="totalRows"
            @row-clicked="displaySale"
            hover>
            
            <template v-slot:cell(rang)="data">
                <span>{{ (currentPage - 1) * perPage + data.index + 1 }}.</span>
            </template>
            
            <template v-slot:cell(marque)="data">
                <div class="brand-cell">
                    <img :src="data.item.cover"/>
                    <p style="min-width: 200px;">
                        <span>{{ shorten(data.item.name, 20) }}</span>
                        <StarRating
                            :rating="pulseToRating(data.item.pulse_ca)" 
                            :read-only="true"
                            :star-size="15"
                            :show-rating="false"
                            :increment="0.5"
                        />
                    </p>
                    <p style="width: 100%; text-align: left;">
                        <span v-if="data.item.appearance == 1" class="sale-tag">1st time</span>
                    </p>
                </div>
            </template>

        </b-table>
        <p style="margin-left: 25px; margin-top: 25px; margin-bottom: 0px;">
            <strong>{{ filteredSales.length }} {{ filteredSales.length > 1 ? "ventes correspondantes" : "vente correspondante" }}</strong>
        </p>
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import DateRangePicker from 'vue2-daterange-picker'
import StarRating from 'vue-star-rating'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { computeQuartiles } from '@/utils/quartiles'
import { debounce } from '@/utils/search'

export default {
    components: {
        Multiselect,
        DateRangePicker,
        StarRating
    },
    data() {
        return {
            filterFields: [],
            filterBadges: [],
            defaultFields: [
                'rang',
                { key: 'marque', formatter: this.fmtBrand },
                { key: 'start', label: 'Date', tdClass: 'date-cell', sortByFormatted: false, sortable: true },
                { key: 'ca', label: 'CA', sortable: true, formatter: this.fmtAmount, tdClass: this.cellStyle, sortDirection: 'desc' },
                { key: 'orders', label: 'Commandes', sortable: true, formatter: this.fmtInteger, tdClass: this.cellStyle, sortDirection: 'desc' },
                { key: 'avg_price', label: 'Prix moyen', sortable: true, formatter: this.fmtAmount, tdClass: this.cellStyle, sortByFormatted: true },
                { key: 'avg_shipping', label: 'Shipping moyen', sortable: true, formatter: this.fmtAmount, tdClass: this.cellStyle, sortByFormatted: true }
            ],
            optionalFields: [
                { text: 'Vues', value: { key: 'click_sale', label: 'Vues', formatter: this.fmtInteger, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Articles consultés', value: { key: 'click_item', label: 'Articles consultés', formatter: this.fmtInteger, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Ajouts au panier', value: { key: 'add_to_cart', label: 'Ajouts au panier', formatter: this.fmtInteger, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Panier moyen', value: { key: 'avg_order', label: 'Panier moyen', formatter: this.fmtAmount, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Conversion', value: { key: 'conversion', label: 'Conversion', formatter: this.fmtRatio, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Abonnés', value: { key: 'followers', label: 'Abonnés', formatter: this.fmtFollowers, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'CA D1', value: { key: 'first_day_ca', label: 'CA D1', sortable: true, formatter: this.fmtAmount, tdClass: this.cellStyle, sortDirection: 'desc' } },
                { text: 'Pulse', value: { key: 'pulse_ca', label: 'Pulse', formatter: this.fmtPulse, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Retours', value: { key: 'returns', label: 'Retours', formatter: this.fmtInteger, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'Réduction moyenne', value: { key: 'avg_discount', label: 'Réduction moyenne', formatter: this.fmtDiscount, tdClass: this.cellStyle, sortable: true, sortDirection: 'desc' } },
                { text: 'ID', value: { key: 'sale_id', label: 'ID' }}
            ],
            currentPage: 1,
            perPage: 15,
            dateRange: { startDate: new Date('2018-11-09'), endDate: new Date() }
        }
    },
    methods: {
        ...mapActions(['updateCurrentSale', 'updateSelectedBadges', 'updateSelectedFields']),
        fmtInteger (value) {
            return Math.floor(value)
        },
        fmtAmount (value) {
            return parseFloat(value).toFixed(2) + '€'
        },
        fmtRatio (value) {
            return parseFloat(value * 100).toFixed(1) + '%'
        },
        fmtFollowers(value) {
            if (value > 1000000) {
                return `${(value / 1000000).toFixed(1)}M`
            }
            return (value > 1000) ? `${parseInt(Math.round(value / 1000))}K` : '<1K'
        },
        fmtDiscount (value) {
            return value.toFixed(0) + '%'
        },
        fmtPulse(value) {
            return (value * 20 / 9).toFixed(2)
        },
        cellStyle (value, key, items) {
            /* Each cell value is assigned a color based on the quartile it belongs to. */
            const x = parseFloat(value)
            if (key in this.quartiles) {
                const b = [
                    'orders', 'ca', 'click_sale', 'click_item', 'add_to_cart', 
                    'conversion', 'followers', 'pulse_ca', 'first_day_ca'].includes(key)
                if (x > this.quartiles[key]['q3'] && items.status === 'closed') {
                    return b ? 'good-sale' : 'bad-sale'
                } else if (x < this.quartiles[key]['q1'] && items.status === 'closed') {
                    return b ? 'bad-sale' : 'good-sale'
                } else if (items.status === 'ongoing') {
                    return 'ongoing-sale'
                } else {
                    return 'avg-sale'
                }
            }
        },
        displaySale(row) {
            this.updateSelectedBadges(this.filterBadges)
            this.updateSelectedFields(this.filterFields)
            this.updateCurrentSale(row)
            const history = this.sales.filter(sale => sale.name === row.name).sort((a, b) => { return new Date(b.start) - new Date(a.start) })
            this.$router.push({ name: 'sale', params: { id: row.sale_id, history: history } })
        },
        pulseToRating(pulse) {
            if (pulse) {
                const p = pulse * 10
                return p > 5 ? 5 : p
            } else {
                return 0
            }
        },
        updateFilter(search) {
            const update = debounce(() => { this.filter = search }, 400)

            if (search.length > 0) {
                update()
            }  
        },
        shorten(name, maxLength) {
            /* Ensure that names do not exceed maxLength characters. */
            return (name.length > maxLength) ? `${name.substring(0, maxLength - 3)}...` : name
        }
    },
    computed: {
        ...mapGetters(['sales', 'categories', 'badges', 'selectedBadges', 'selectedFields', 'isGuest']),
        ...mapGetters('elastic', ['index']),
        filter: {
            get() {
                return this.$store.state.sales.filter
            },
            set(value) {
                this.$store.commit('setFilter', value)
            }
        },
        totalRows() {
            return Array.isArray(this.sales) ? this.filteredSales.length : 0
        },
        quartiles() {
            return computeQuartiles(this.sales)
        },
        filteredSales() {

            let result
            
            if (this.filter && !this.categories.includes(this.filter)) {

                result = this.index.search(
                    this.filter, 
                    { 
                        fields: {
                            brand: { expand: true }
                        }
                    }
                ).map((doc) => { 
                    return this.sales.find(row => row.sale_id == doc.ref)
                })
            } else if (this.filter && this.categories.includes(this.filter)) {
                result = this.sales.filter(row => row.categories.includes(this.filter))
            } else {
                result = this.sales
            }
  
            result = result.filter((row) => {
                return (
                    (row.start >= this.dateRange.startDate.toISOString().slice(0, 10)) && 
                    (row.start <= this.dateRange.endDate.toISOString().slice(0, 10))
                )
            })

            if (this.filterBadges.length > 0) {
                return result.filter(sale => this.filterBadges.every(badge => sale.badges.includes(badge)))
            } else {
                return result
            }
        },
        fields() {
            return this.defaultFields.concat(this.filterFields)
        },
        csvFields() {
            return ['name', 'start', 'ca', 'orders', 'avg_price', 'avg_shipping'].concat(this.filterFields.map((el) => el.key))
        }
    },
    mounted() {
        this.filterBadges = this.selectedBadges
        this.filterFields = this.selectedFields
    }
}
</script>

<style>
.datatable {
    width: 100%; 
    padding: 25px;
}

.sales-table, .sales-filter {
    min-width: 700px;
    padding: 25px;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
}

.sales-filter {
    margin-bottom: 25px;
    min-height: 120px;
}

.badge-btn {
    background: white; 
    color: black;
    border-radius: 15px;
    font-size:15px;
    border: none;
    border: 1px solid #ccc;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.badge-btn:hover {
    background: black;
    color: white;
    border: 1px solid white;
}

.multiselect {
    width: 50%;
    min-width: 400px;
}

.multiselect__tags {
    border-radius: 10px;
}

.multiselect__option--highlight, .multiselect__option--highlight::after {
    background: #0794dc;
}

.reportrange-text {
    border-radius: 5px !important;
}

.reportrange-text > span {
    padding-left: 10px;
    padding-right: 10px; 
    font-size: 14px;
}

.table thead th {
    border: none;
    font-size: 14px;
}

.table thead {
    text-align: right;
}

.table td {
    border-top: none !important;
    font-size: 14px;
    vertical-align: top;
    padding-bottom: 0px !important;
}

[aria-colindex="1"] {
    padding-top: 15px;
    text-align: right;
}

[aria-colindex="2"] {
    text-align: left;
}

.good-sale, .bad-sale, .avg-sale, .ongoing-sale {
    text-align: right;
    font-weight: 700;
}

.good-sale {
    color: #009432 !important;
}

.bad-sale {
    color: red !important;
}

.avg-sale {
    color: rgba(0, 0, 0, 0.75) !important;
    font-weight: 600;
}

.ongoing-sale {
    opacity: 0.4;
    color: black !important;
}

.brand-cell {
    display: flex;
}

.brand-cell > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.brand-cell > p {
    margin-bottom: 5px;
}

.brand-cell > p > span {
    padding-left: 15px;
    text-align: left;
    font-family: Poppins;
    color: rgba(0, 0, 0, 0.6);
}

.sale-tag {
    padding-right: 7px;
    font-size: 10px;
    padding-left: 7px !important;
    background: #78e08f;
    border-radius: 15px;
    color: white !important;
    white-space: nowrap;
}

.date-cell {
    text-align: right;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

.vue-star-rating {
    padding-left: 8px;
}

.page-link {
    color: #0794dc !important;
}

.page-link:hover {
    color: #0794dc !important;
    background-color: white;
}

.page-item.active .page-link {
    background-color: #0794dc !important;
    border-color: #0794dc !important;
    color: white !important;
}

.page-link:focus {
    box-shadow: unset;
}

.download-link:hover {
  color:#007bff;
  cursor: pointer;
}

.vue-daterange-picker {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}
</style>