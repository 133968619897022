<template>
    <div class="row sale">
        <div class="col-md-8">
            <div class="sale-left">
                <div class="sale-card">

                    <img v-if="cover" :src="cover"/>
                    
                    <div class="sale-card-info">
                        <h3>
                            <a :href="meusnidus" target="_blank" class="meusnidus-link">
                                {{ sale ? sale.name : '' }}
                            </a>
                        </h3>

                        <p>
                            <img src="@/assets/instagram.jpg"/>
                            <span><strong>{{ fmtFollowers(sale ? sale.followers : 0) }}</strong> abonnés</span>
                        </p>

                        <p> 
                            <font-awesome-icon icon="hand-holding-usd" style="font-size: 20px;"/>
                            <span><strong>{{ soldItems }}</strong> articles vendus pour
                            un total de <strong>{{ sale ? sale.orders : 0 }}</strong> commandes.</span> 
                        </p>

                        <p style="display: flex;">
                            <font-awesome-icon icon="calendar-alt" style="font-size: 20px;"/>
                            <span><strong>{{ sale ? sale.start : '' }}</strong></span>
                        </p>
                    </div>

                </div>
    
                <TextLoader v-if="isLoading" message="Chargement des données"/>

                <div v-else>
                    <div class="items-container">

                        <div class="row table-head">
                            <div class="col-sm-2">
                                <span></span>
                            </div>
                            <div class="col-sm-4">
                                <span><strong>Référence</strong></span>           
                            </div>
                            <div class="col-sm-2">
                                <span><strong>Prix</strong></span>
                            </div>
                            <div class="col-sm-2">
                                <span><strong>Réduction</strong></span>
                            </div>
                            <div class="col-sm-2">
                                <span><strong>Ventes</strong></span>
                            </div>
                        </div>

                        <transition-group appear name="list">
                            <div class="row item-card" v-for="item in items" :key="item.item_id">
                                <div class="col-sm-2">
                                    <img :src="item.image"/>
                                </div>
                                <div class="col-sm-4">
                                    <span><strong>{{ shorten(item.name, 32) }}</strong></span>
                                    <br>      
                                    <span>{{ fmtCategory(item.category) }}</span>           
                                </div>
                                <div class="col-sm-2">
                                    <span><strong>{{ fmtAmount(item.price) }}</strong></span>
                                    <br>
                                    <del style="color: rgba(0,0,0,0.5);">{{ fmtAmount(item.original_price) }}</del>
                                </div>
                                <div class="col-sm-2">
                                    <span>{{ item.discount }}%</span>
                                </div>
                                <div class="col-sm-1">
                                    <span>{{ item.quantity }}</span>
                                </div>
                                <div class="col-sm-1">
                                    <span v-if="isBestSeller(item.quantity)">🔥</span>
                                </div>
                            </div>
                        </transition-group>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <History :saleId="sale.sale_id" :history="this.$route.params.history"/>
            <Scoreboard :sale="sale" :categories="sale.categories"/>
            <div class="daterange-selector">
                <span><strong>Détails</strong></span>
                <hr>
                <input type="radio" id="default" value="default" v-model="daterange">
                <label for="default" style="margin-left: 5px;">Toute la vente</label>
                <br>
                <input type="radio" id="one" value="1" v-model="daterange">
                <label for="one" style="margin-left: 5px;">Premier jour</label>
                <br>
                <input type="radio" id="two" value="2" v-model="daterange">
                <label for="two" style="margin-left: 5px;">Deuxième jour</label>
                <hr>
                <div v-if="!isGuest" style="text-align: center;">
                    <download-csv :data="items" :fields="fields" :name="filename">
                        <span class="download-link">
                        <font-awesome-icon icon="download"/>
                        Télécharger le .csv
                        </span>
                    </download-csv>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextLoader from '@/components/TextLoader'
import History from '@/components/History'
import Scoreboard from '@/components/Scoreboard'

export default {
    components: {
        TextLoader,
        History,
        Scoreboard
    },
    data() {
        return {
            daterange: 'default',
            fields: ['name', 'reference', 'gtin', 'price', 'original_price', 'discount', 'quantity']
        }
    },
    watch: {
        daterange(val) {
            this.loadItems({ saleId: this.sale.sale_id, daterange: val })
        },
        sale(val) {
            this.loadItems({ saleId: val.sale_id, daterange: this.daterange })
        }
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                if (this.$route.name != 'home') {
                    this.$router.push({ name: 'home' })
                }
            }
        })
    },
    computed: {
        ...mapGetters(['items', 'currentSale', 'isGuest']),
        ...mapGetters('loading', ['isLoading']),
        sale() {
            return this.currentSale
        },
        cover() {
            return this.sale ? this.sale.cover : ''
        },        
        soldItems() {
            const reducer = (acc, item) => acc + item.quantity
            return this.items.reduce(reducer, 0)
        },
        filename() {
            const name = this.sale.name.replaceAll(' ', '_').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            return `sales_analytics_${name}.csv`
        },
        meusnidus() {
            return `https://meusnidus.appchoose.io/vente/${this.sale.sale_id}`
        }
    }, 
    methods: {
        ...mapActions(['loadItems', 'resetItems']),
        shorten(name, maxLength) {
            /* Ensure that names do not exceed maxLength characters. */
            return (name.length > maxLength) ? `${name.substring(0, maxLength - 3)}...` : name
        },
        fmtFollowers(value) {
            return (value > 1000) ? `${parseInt(Math.round(value / 1000))}K` : '<1K'
        },
        fmtCategory(value) {
            return value ? this.shorten(value.split('>').slice(-1)[0], 35) : ''
        },
        fmtAmount(value) {
            return parseFloat(value).toFixed(2) + '€'
        },
        isBestSeller(value) {
            if (this.soldItems > 50) {
                if (this.sale.items > 5 && this.sale.items <= 10) {
                    return value / this.soldItems > 0.3
                } else if (this.sale.items > 10 && this.sale.items <= 20) {
                    return value / this.soldItems > 0.2
                } else if (this.sale.items > 20) {
                    return value / this.soldItems > 0.1
                } else {
                    return false
                }
            } else {
                return false
            }

        }
    },
    mounted() {
        if (this.sale) {
            this.loadItems({ saleId: this.sale.sale_id, daterange: "default" })
        } else {
            this.$router.push('/')
        }
    },
    beforeRouteLeave (to, from, next) {
        this.resetItems()
        next()
    }
}
</script>

<style scoped>
.sale {
    width: 100%; 
    padding: 25px;
    margin: auto;
    max-width: 1150px;
}

.sale-left {
    margin: auto;
    border-radius: 25px;
    max-width: 700px;
    min-width: 600px;
}

.sale-card {
    display: flex; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
    background: white; 
    margin-bottom: 25px; 
    border-radius: 10px;
}

.sale-card > img {
    width: 333px;
    height: 222px;
    border-radius: 10px 0px 0px 10px;
}

.sale-card-info {
    margin-left: 10px; 
    padding: 10px
}

.sale-card-info > h3 {
    margin-bottom: 15px;
}

.sale-card-info > p > img {
    width: 25px;
}

.sale-card-info > p > span {
    margin-left: 5px;
}

.meusnidus-link {
    color: black;
}

.meusnidus-link:hover {
    color: #007bff;
    text-decoration: none;
}

.table-head, .item-card {
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background: white;
    font-size: 14px;
}

.table-head:first-child {
    padding: 5px;
}

.item-card > div > img {
    height: 90px;
    width: 90px;
    border-radius: 10px 0px 0px 10px;
}

.col-sm, .col-sm-2, .col-sm-4, .col-sm-1 {
    margin: auto;
}

.col-sm-2, .col-sm-4 {
    padding: 0;
}

.daterange-selector {
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 25px;
    max-width: 500px;
    min-width: 350px;
    padding: 15px;
    margin-bottom: 25px;
}

.download-link:hover {
  color:#007bff;
  cursor: pointer;
}

.list-enter-active, .list-leave-active {
    transition: all 0.5s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
</style>