import elasticlunr from 'elasticlunr'

const namespaced = true

const state = {
    index: []
}

const getters = {
    index: (state) => state.index
}

const mutations = {
    setIndex: (state, index) => { 
        state.index = index 
    }
}

const actions = {
    populateIndex({ commit }, table) {

        const normalizer = function (token) { 
            return token.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }
        
        elasticlunr.Pipeline.registerFunction(normalizer, 'normalizer')

        var index = elasticlunr(function () {
            const fn = elasticlunr.Pipeline.getRegisteredFunction('normalizer')
            this.pipeline.add(fn)
            this.addField('brand')
            this.setRef('id')
        })

        for (let i = 0; i < table.length; i++) {
            var doc = {
                "id": table[i].sale_id,
                "brand": table[i].name
            }
            index.addDoc(doc)
        }
        
        commit('setIndex', index)
    }
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}