<template>
    <div class="sidebar">
        
        <p class="sidebar-item" @click="redirect('/')">
            <font-awesome-icon icon="home"/>
        </p>
        
        <p class="sidebar-item" @click="redirect('/insight')">
            <a><font-awesome-icon icon="chart-bar"/></a>
        </p>

        <p class="sidebar-item" @click="redirect('/login')">
            <a><font-awesome-icon icon="user"/></a>
        </p>

        <p v-if="isAdmin" class="sidebar-item" @click="update">
            <a><font-awesome-icon icon="sync"/></a>
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    methods: {
        redirect(path) {
            if (path != this.$router.currentRoute.path && this.isAuthenticated) {
                this.$router.push({ path: path })
            }
        },
        async update() {
            const auth = window.gapi.auth2.getAuthInstance()
            const headers = {}
            if (auth.isSignedIn.get()) {
                headers.Authorization = `Bearer ${auth.currentUser.get().getAuthResponse().id_token}`
            }
            const response = await fetch('sales/reset', {
                method: 'GET',
                headers
            })
            
            if (response.status == 200) {
                this.$router.go()
                console.log("Data have been successfully updated.")
            } else {
                console.log("Something went wrong.")
            }
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'user']),
        isAdmin() {
            if (this.user) {
                return this.user.getBasicProfile().getId() == '112216587260792212147'
            } else {
                return false
            }
        }
    }
}
</script>

<style>
.sidebar {
    width: 76px;
    min-width: 76px;
    min-height: 1250px;
    background: black;
    color: white;
    text-align: center;
    bottom: 0;
}

.sidebar-item {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 30px;
}

.sidebar-item:hover {
    background: white;
    color: black;
    cursor: pointer;
}

.sidebar-item > a {
    color: white;
}

.sidebar-item:hover > a {
    color: black;
}
</style>