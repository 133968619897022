import Vue from 'vue'
import Vuex from 'vuex'
import categories from './modules/categories'
import sales from './modules/sales'
import items from './modules/items'
import loading from './modules/loading'
import elastic from './modules/elastic'
import login from './modules/login'
import filters from './modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        filters,
        categories,
        sales,
        items,
        loading,
        elastic,
        login
    }
})