import { GraphQLClient, gql } from 'graphql-request'

const state = {
    items: []
}

const getters = {
    items: (state) => state.items
}

const mutations = {
    setItems: (state, data) => {
        state.items = data
    }
}

const actions = {
    async loadItems({ commit, dispatch }, { saleId, daterange }) {
        dispatch('loading/startLoader', null, { root: true })

        let params

        if (daterange == 'default') {
            params = 'sale_id: $saleId'
        } else if (daterange == '1') {
            params = 'sale_id: $saleId, start: 0, end: 1'
        } else if (daterange == '2') {
            params = 'sale_id: $saleId, start: 1, end: 2'
        }

        const query = gql`
            query getItems($saleId: String!) {
                items(${params}) {
                    item_id
                    name
                    category
                    quantity
                    price
                    original_price
                    image
                    discount
                    gtin
                    reference
            }
        }`
        // const url = process.env.VUE_APP_GRAPHQL_ENDPOINT + '/items'
        const url = '/items'
        const variables = {
            saleId: saleId
        }

        const auth = window.gapi.auth2.getAuthInstance()
        const headers = {}
        if (auth.isSignedIn.get()) {
            headers.Authorization = `Bearer ${auth.currentUser.get().getAuthResponse().id_token}`
        }
        const client = new GraphQLClient(url, { headers: headers })
        const data = await client.request(query, variables)

        // const data = await request(url, query, variables)
        commit('setItems', data.items)
        dispatch('loading/stopLoader', null, { root: true })
    },
    resetItems({ commit }) {
        commit('setItems', [])
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}