<template>
    <div class="scoreboard">
        <p style="margin-bottom: 10px;">
            <span><strong>Scores</strong></span>
        </p>
        <b-form-select v-model="selectedCategory" :options="categories"></b-form-select>

        <hr>
        <Scorebar name="Prix moyen" :category="selectedCategory" :quartiles="quartiles.avg_price" :score="scores.avg_price" :mean="sale.avg_price"/>
        <Scorebar name="Réduction" :category="selectedCategory" :quartiles="quartiles.avg_discount" :score="scores.avg_discount" :mean="sale.avg_discount"/>
        <Scorebar name="Abonnés" :category="selectedCategory" :quartiles="quartiles.followers" :score="scores.followers" :mean="sale.followers"/>
        <Scorebar name="Livraison" :category="selectedCategory" :quartiles="quartiles.avg_shipping" :score="scores.avg_shipping" :mean="sale.avg_shipping"/>
        <Scorebar name="Articles disponibles" :category="selectedCategory" :quartiles="quartiles.items" :score="scores.items" :mean="sale.items"/>
        <Scorebar name="Retours" :category="selectedCategory" :quartiles="quartiles.returns" :score="scores.returns" :mean="sale.returns"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scorebar from '@/components/Scorebar'
let { jStat } = require('jstat')

export default {
    props: {
        sale: Object,
        categories: Array
    },
    components: {
        Scorebar
    },
    data() {
        return {
            selectedCategory: ''
        }
    },
    methods: {
        compare(a, b) {
            if (b[1] > 0) {
                return (a / b[1]) * 50
            } else {
                if (a == 0) {
                    return 0
                } else {
                    return 100
                }
            }
        },
        computeQuartiles(values) {
            if (values) {
                return jStat.quartiles(values)
            } else {
                return [0, 0, 0]
            }
        }
    },
    mounted() {
        this.selectedCategory = this.categories[0]
    },
    computed: {
        ...mapGetters(['sales']),
        categorySales() {
            return this.sales.filter(row => row.categories.includes(this.selectedCategory) && row.pulse_orders > 0)
        },
        X() {
            return this.categorySales.map(row => [row['avg_price'], row['avg_discount'], row['followers'], row['avg_shipping'], row['items'], row['returns']])
        },
        Xt() {
            return jStat.transpose(this.X)
        },
        quartiles() {
            if (this.Xt) {
                return {
                    avg_price: this.computeQuartiles(this.Xt[0]),
                    avg_discount: this.computeQuartiles(this.Xt[1]),
                    followers: this.computeQuartiles(this.Xt[2]),
                    avg_shipping: this.computeQuartiles(this.Xt[3]),
                    items: this.computeQuartiles(this.Xt[4]),
                    returns: this.computeQuartiles(this.Xt[5])
                }
            } else {
                return {
                    avg_price: 0,
                    avg_discount: 0,
                    followers: 0,
                    avg_shipping: 0,
                    items: 0,
                    returns: 0
                }
            }
        },
        scores() {
            if (this.sale) {
                return {
                    avg_price: 100 - this.compare(this.sale.avg_price, this.quartiles.avg_price),
                    avg_discount: this.compare(this.sale.avg_discount, this.quartiles.avg_discount),
                    followers: this.compare(this.sale.followers, this.quartiles.followers),
                    avg_shipping: 100 - this.compare(this.sale.avg_shipping, this.quartiles.avg_shipping),
                    items: this.compare(this.sale.items, this.quartiles.items),
                    returns: 100 - this.compare(this.sale.returns, this.quartiles.returns)
                }
            } else {    
                return {
                    avg_price: 0,
                    avg_discount: 0,
                    followers: 0,
                    avg_shipping: 0,
                    items: 0,
                    returns: 0
                }
            }
        
        }
    }
}
</script>

<style>
.scoreboard {
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    max-width: 500px;
    min-width: 350px;
    padding: 15px;
    margin-bottom: 25px;    
}

</style>